////
/// Header Layout
/// @group Layout
////


// Header
//----------------------------------------------------
.l-header {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: visible;
  // @include bleed-x(100%);
  background-color: #fff;
  max-width: 960px;
  border-bottom: 1px solid #d7d7d7;
}

// Header region
//----------------------------------------------------
.l-header-region {
  display: flex;
  flex-wrap: wrap;
  
  @media screen and (max-width: 400px) {
    flex: 1 1 100%;
    justify-content: center;
    section:nth-child(1){
      order:1;
    }
    section:nth-child(2){
      order:2;
    }
    section:nth-child(3){
      order:3;
    }
  }
}
