// IMAGES
//----------------------------------------------
img,
figure img,
media {
  max-width: 100%;
  height: auto;
}

// FIGURE
//----------------------------------------------
figure {
  position: relative;
  margin: 0;
  padding: 0;
  img {
    display: block;
    // width: 100%;
  }
}
figcaption {
}

figure {
  &.field-type-video-embed-field,
  &.group-paragraph-figure {
    max-width: 100%;
    height: auto;
    min-width: 80%;
    // min-width: 100%;
  }
}

// PARAGRAPHS
//----------------------------------------------

.paragraphs-items {
  width: 100%;
}

// FIELDS
//----------------------------------------------

.field-name-field-url-video-embed {
  margin: 10px auto;
}

.field-name-field-txt-figcaption {
  padding: 10px 20px;
}

.node-type-project .l-main {
  // figure.field-type-video-embed-field {
  figure.field-name-field-url-video-embed {
    max-width: 80%;
  }
}

// SLICK
//----------------------------------------------
// Prevent carousel overflow by using container width and spacing
.slick-wrapper {
  @extend %container;
}

// Add spacing to individual thumbnails
.slick--display--thumbnail.slick--skin--asnavfor .slick__slide {
  margin-top: 24px;
  margin-bottom: 48px;
}
