// SEARCH PAGE / SEARCH BLOCK
//----------------------------------------------
.search-form,
.block-search {
  input[type="search"] {
    border-width: 0 0 1px 0 !important;
    border-radius: 0 !important;
  }
  input[type="submit"] {
    font-family: $icon;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: $color-link;
    &:hover {
      color: $color-link-hover;
    }
  }
}

// SEARCH PAGE
//----------------------------------------------
// .search-form {
//   label {}
//   input[type="text"] {}
//   input[type="submit"] {
//     &:hover {}
//   }
// }

// SEARCH BLOCK
//----------------------------------------------
.block-search {
  float: right;
  // input[type="text"] {}
  input[type="submit"] {
    &:hover {}
  }
}

// SEARCH RESULTS
//----------------------------------------------
.page-search {
  h1.page-title {}
}

.search-result {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-divider;
  strong {
    background: yellow;
  }
  h3.title {
    margin: 0;
    @include font-size(24px);
    font-weight: normal;
  }
}
