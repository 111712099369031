.b-footer {
  // @include bleed-x(100%);
  box-sizing: content-box;
  margin-right: -100%;
  margin-left: -100%;
  padding-right: 100%;
  padding-left: 100%;
  @include font-size(12px);
  padding: 14px;
  text-align: center;
  background: $black;
  color: #fff;
}
