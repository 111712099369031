.view-filters {
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 30px;
  padding-bottom: 5px;
  form {
    margin-bottom: 16px;
    label {
      text-transform: uppercase;
      // font-family: $uniformCondensed;
      font-weight: 700;
      padding: 0px 8px;
      @include font-size(12px);
    }
    select {
      border-radius: 0px;
      -webkit-appearance: none;
      border: 0px;
      padding: 4px 8px;
      margin: 4px 8px;
      color: black;
      background: transparent;
      border-bottom: 1px solid black;
      // font-family: $uniformCondensed;
      @include font-size(12px);
    }

    .form-item-title {
      padding: 3px 8px;
    }

    .views-submit-button {
      input {
        margin-top: 17px !important;
        background: black;
        color: #fff;
        // font-family: $uniformCondensed;
        &:hover {
          background: $color-link-hover;
          color: #fff;
        }
      }
    }
  }
}
