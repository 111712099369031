/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// LAYOUT
//----------------------------------------------------

// Container
//----------------------------------
%container {
  // @include container;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
}

// Wide Container
//----------------------------------
%wide {
  //   @include bleed-x(100%);
  margin-right: -100%;
  margin-left: -100%;
  padding-right: 100%;
  padding-left: 100%;
}

// Clearfix
//----------------------------------
%clearfix {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

// LISTS
//----------------------------------------------------

// Lists Reset
//----------------------------------
%list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none;
}

// Inline Block
//----------------------------------
%inline-block {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  zoom: 1;
}

// IMAGES
//----------------------------------------------------

// Image replacement
//----------------------------------
%replace-image {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
}

// ICONS
//----------------------------------------------------

// Icon before static settings
//----------------------------------
%icon-before {
  &:before {
    @extend %inline-block;
    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    }
    text: {
      decoration: inherit;
      transform: none;
    }
    speak: none;
    line-height: 1em;
    width: 1em;
  }
}

// Icon after static settings
//----------------------------------
%icon-after {
  &:after {
    @extend %inline-block;
    vertical-align: baseline !important;
    font: {
      family: $icon;
      style: normal;
      weight: normal;
      variant: normal;
    }
    text: {
      decoration: inherit;
      transform: none;
    }
    speak: none;
    line-height: 1em;
    width: 1em;
  }
}

// BUTTONS
//----------------------------------------------------

// Button Static Setings
//----------------------------------
%btn {
  display: block;
  margin: 20px 0;
  a {
    border-radius: 4px;
    padding: 6px 12px;
    color: #fff;
    @include on-event {
      color: #fff;
      cursor: pointer;
    }
  }
}
