.node-type-organization, .node-type-program, .node-type-project, .node-type-article, .node-type-event {

  .node, .l-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2%;

    .group-text {
      flex: 1 1 50%;
      font-weight: 500;
      line-height: 18px;
      @media screen and (max-width: 768px){
        flex: 1 1 100%;
      }
      h2.page-title {
        text-transform: uppercase;
        @include font-size(40px);
        font-weight: 600;
        line-height: 38px;
      }
    }
    .group-media {
      flex: 1 1 50%;
      @media screen and (max-width: 768px){
        flex: 1 1 100%;
      }
      .field-name-field-img-main {
        width: 100%;
        max-width: 100%;
      }
    }
    .group-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 2%;
      flex: 1 1 100%;
      padding: 1.2% 1%;
      border-top: 1px solid #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
    }
    .field-name-body {
      flex: 1 1 100%;
      padding: 2% 0;
    }

    .block-views {
      flex: 1 1 100%;
    }
    @media screen and (max-width: 1100px){
      .paragraphs-items{
        width: 91vw;
      }
    }
  }
}

.node-program{
  img {
    padding-left: 100px;
    padding-top: 20px;
  }
}
