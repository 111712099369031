////
/// Sitewide Layout
/// @group Layout
////

// Global
//----------------------------------------------------
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.l-page {
  @include bg-img(footer, 100%);
  background-position: center bottom;
  background-repeat: repeat-x;
}
.l-container {
  @extend %container;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible;
}
.l-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 140px;
}
.l-content {
  order: 2;
}
.l-sidebar-first {
  order: 1;
}
.l-sidebar-second {
  order: 3;
}

// Medium
//----------------------------------------------------
@media (min-width: $m) {
  .l-main {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .l-content {
    flex: 66.1016949153%;
    order: 1;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 32.2033898305%;
    margin-left: auto;
  }
}

// Large
//----------------------------------------------------
@media (min-width: $l) {
  .l-main {
    flex-wrap: nowrap;
  }
  .l-content {
    flex: 74.5762711864%;
    order: 2;
  }
  .l-sidebar-first,
  .l-sidebar-second {
    flex: 0 1 23.7288135593%;
  }
}
