.b-mailchimp-signup {

  @include font-size(12px);
  margin: 20px auto;

  #mc_embed_signup_scroll {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: $m){
      justify-content: space-between;
      text-align: center;
    }
  }

  label {
    margin-right: 20px;
    line-height: 28px;
    font-weight: 900;
    @include font-size(10px);
    text-transform: uppercase;
    @media screen and (max-width: $m){
      width: 100%;
      margin-right: 0px;
    }
  }
  input[type="email"],
  input[type="submit"] {
    font-family: $geomanist !important;
    border: none !important;
    border-radius: 0;
    @media screen and (max-width: $m){
      width: 100%;
      min-height: 28px;
      text-align: center
    }
  }

  input[type="email"] {
    padding: 2px 20px 2px 20px;
    text-align: center
  }
  .clear {
    @media screen and (max-width: $m){
      width: 100%;
    } 
  }
  input[type="submit"] {
    font-weight: 800;
    letter-spacing: 2px;
    @include font-size(10px);
    text-transform: uppercase;
    color: #fff;
    padding: 8px 30px 6px;
    &:hover {
      background: $color-accent;
      color: #000;
    }
  }
}

