.page-node {
  .field-name-title {
    h1 {
      margin-top: 3%;
      margin-bottom: 3%;
      border-bottom: 1px solid #d7d7d7;
      text-align: center;
      text-transform: uppercase;
      @include font-size(90px);
      font-weight: 800;
      @media screen and (max-width: 400px) {
        @include font-size(40px);
      }
      @media screen and (max-width: 768px) {
        @include font-size(50px);
      }
    }
  }

  .field-name-body {
    text-align: justify;
    color: #444;
  }
}
