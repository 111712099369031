////
/// Content Types Layout
/// @group Layout
////

.node-type-project {
  .ds-2col-stacked-fluid.view-mode-full {
    // Layout
    display: flex;
    flex-direction: row;
    .group-header,
    .group-left,
    .group-right,
    .group-footer {
      // flex-basis: span(full);
      flex-basis: 100%
    }
    .group-left,
    .group-right {
      padding: 10px;
    }
      // @include breakpoint($m) {
      @media (min-width:$m) {
      .group-left {
        // flex-basis: span(7);
        flex-basis: 57.6271186441%
      }
      .group-right {
        // flex-basis: span(5);
        flex-basis: 40.6779661017%
      }
    }

    // Header
    .group-header {
      display: flex;
      flex-wrap: wrap;
      .group-text {
        flex: 1 1 50%;
        font-weight: 500;
        line-height: 18px;
        @media screen and (max-width: 768px) {
          flex: 1 1 100%;
        }
        h2.page-title {
          text-transform: uppercase;
          @include font-size(40px);
          font-weight: 600;
          line-height: 38px;
        }
      }
      .group-media {
        flex: 1 1 50%;
        @media screen and (max-width: 768px) {
          flex: 1 1 100%;
        }
        .field-name-field-img-main {
          width: 100%;
          max-width: 100%;
        }
      }
    } // .group-header
  } // .ds-2col-stacked-fluid.view-mode-full
} // .node-type-project
