.b-departamentos.node-grid {
  h2.block-title {

  }
  .view-content {
    display: flex;
    flex-wrap: wrap;
    .views-row {
      text-align: center;
      flex: 1 0 300px;
      margin-bottom: 20px;
      padding: 0 10px;

      h2.title {
        a {
          display: block;
          padding: 10px;
          background: black;
          color: $color-link;

          &:hover {
            color: black;
            background: $color-link;
          }
        }
      }

      // @include breakpoint($s) {
      @media (min-width:$s) {
        max-width: 50%;
      }
      // @include breakpoint($m) {
      @media (min-width:$m) {
        max-width: 50%;
      }
    } // .views-row
  } // .view-content
}
