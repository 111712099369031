// Nodes Grid (ALT)
//--------------------------------------------------------------

.node-grid-alt {

  // Layout
  //----------------------------
  .view-content {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }

  // Group Header
  //----------------------------
  h3.views-group-title {
    grid-column: 1/-1;
    margin: 0 0 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid $color-divider;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    @include font-size(36px);
    @media screen and (max-width: 400px) {
      @include font-size(24px);
    }
  }

} // .node-grid-alt
