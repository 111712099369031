////
/// Variables
/// @group Abstracts
/// This file contains application-wide Sass variables.
////

@import "fonts";
@import "colors";
@import "icons";

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: "../" !default;

// Susy
//----------------------------------------------------
// @include border-box-sizing;
// $susy: (
//   columns: 12,
//   container: 1000px,
//   global-box-sizing: border-box
// );

// Breakpoints
//----------------------------------------------------
$xs: 320px;
$s: 480px;
$m: 768px;
$l: 960px;

// Colors
//----------------------------------------------------
$color-primary: #04c6de;
$color-accent: #f9f608;
$color-primary-text: $black;
$color-secondary-text: $gray;
$color-divider: $silver;

$base-font-color: $color-primary-text;
$color-link: $color-primary;
$color-link-hover: darken($color-link, 10%);

$magon-background: #132b3a; // #003333; // #144453;
$magon-background-shadow: rgba($magon-background, 0.22);
$magon-color: $white;
$magon-accent: #008080; // #1d3844; // #2e4b54;

// Typography
//----------------------------------------------
$base-font-family: $geomanist;
$base-font-weight: 300;
$base-font-size: 16px;
$base-line-height: 24px;
$header-font-family: $geomanist;
$header-font-weight: 500;
