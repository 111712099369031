////
/// Main menu
/// @group Components
////

// Main Menu
//------------------------------
.l-nav {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
  flex-direction: column;
  .block-search,
  .nav-main {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (min-width: $s) {
    .block-search,
    .nav-main {
      margin-left: auto;
    }
  }
  @media (min-width: $m) {
    flex-direction: row;
    justify-content: space-between;
  }
  // @media (max-width: 450px) {
  //   min-width: 100%;
  // }
}

// Site Header Main Menu
// -----------------------------
.nav-main {
  @include when-inside(".l-header") {
    position: relative;
    z-index: 999;
    align-self: center;

    // Menu Title
    // -------------------------
    h2 {
      display: flex;
      align-content: center;
      align-items: center;
      transition: all 0.3s;
      margin: 0;
      padding: 4px 10px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
      background-color: #000;
      cursor: pointer;
      &:after {
        margin-left: 40px;
      }
      &:hover {
        div.icon {
          width: 15px;
          margin-left: 41px;
          &:before,
          &:after {
            width: 15px;
          }
          &:before {
            margin-left: 0px;
          }
        }
      }
      // Menu Title Icon
      // -----------------------
      div.icon {
        position: relative;
        right: 0px;
        margin-left: 45px;
        float: right;
        width: 11px;
        height: 3px;
        background: #fff;
        transition: all 0.3s;
        &:before,
        &:after {
          position: absolute;
          transition: all 0.3s;
          content: "";
          height: 3px;
          background: #fff;
        }
        &:before {
          margin-left: -4px;
          top: -4px;
          width: 15px;
        }
        &:after {
          top: 4px;
          width: 11px;
        }
      }
    }
    // Menu Items L1
    // -------------------------
    > ul.menu {
      display: none;
      position: absolute;
      right: 0px;
      min-width: 280px;
      // @media (max-width: 450px) {
      //   text-align: center;
      // }
      > li {
        a,
        span {
          display: block;
          padding: 1em 2em;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1px;
          background-color: #000;
          color: #fff;
          &:hover {
            color: $color-primary;
          }
        }
        span {
          cursor: pointer;
          &:after {
            margin-left: 10px;
            // font-size: 18px;
          }
          @include icon-after(down);
          &.open {
            @include icon-after(up);
          }
        }
        &.first a {
          padding-top: 2em;
        }
        &.last a {
          padding-bottom: 2em;
        }
      }
      // Menu Items L2
      // -------------------------
      ul {
        display: none;
        li {
          a,
          span {
            font-weight: normal;
            text-transform: none;
            color: #fff;
            &:hover {
              background-color: #656565;
              color: #fff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
} // .nav-main
