// Global
//----------------------------------------------------
html {
  line-height: 1.5
}
body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  color: $base-font-color;
}

// Headers
//----------------------------------------------------
h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  font-style: normal;
  line-height: 1.2;
}

h1 {
  // @include font-size($base-font-size * 2);
  margin: 0 0 20px 0;
  padding: 10px 0;
  border-bottom: 1px solid $color-divider;
  text-align: center;
  text-transform: uppercase;
  @include font-size(90px);
  font-weight: 800;
  @media screen and (max-width: 400px) {
    @include font-size(40px);
  }
  @media screen and (max-width: 768px) {
    @include font-size(50px);
  }
}

h2 {
  @include font-size($base-font-size * 1.5);
}

h3 {
  @include font-size($base-font-size * 1.25);
}

h4 {
  @include font-size($base-font-size * 1);
}

h5 {
  @include font-size($base-font-size * 0.8);
}

h6 {
  @include font-size($base-font-size * 0.6);
}

// Body
//----------------------------------------------------

b, strong, label {
  font-weight: 500;
}

small {
  font-size: 80%;
}

blockquote, q {
  quotes: none;
  margin: 0;
  font-style: italic;
}

// Links
//----------------------------------------------------

a:link, a:visited, a:active, li a.active {
  transition: all 250ms ease-in-out;
  text-decoration: none;
  color: $color-link;
  border: none;
  background-color: transparent;
  @include on-event {
    color: $color-link-hover;
    outline: 0;
  }
}

// Element invisible
//----------------------
.element-invisible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}
