// CONTACT INFO
//----------------------------------------------

.node-type-contact {
  .view-mode-full {
    margin-bottom: 80px;
  }

  .field-name-field-img-main {
    img {
      background: $silver;
      border-radius: 100%;
      max-width: 50%;
    }

    // @include breakpoint($m) {
    @media (min-width:$m) {
      float: right;
      margin-left: 10px;
    }
  }

  .field-name-field-txt-rol {
    font-weight: 500;
    margin-bottom: 20px;
  }

  .field-name-field-email,
  .field-name-field-telephone,
  .field-name-field-ref-organization {
    @include font-size(14px);
    &:before {
      margin-right: 8px;
    }
    a {
      color: $black;
      &:hover {
        color: $color-link;
      }
    }
  }

  .field-name-field-email {
    @include icon-before(mail);
  }
  .field-name-field-telephone {
    @include icon-before(phone);
  }
  .field-name-field-ref-organization {
    @include icon-before(tags);
  }
}

//----------------------------------------------
//----------------------------------------------

.b-contact-info {
  .view-contactos-new {
    .views-row {
      text-align: center;
    }
  }

  #edit-field-tax-contact-type-tid-wrapper {
    .not-logged-in & {
      display: none;
    }
  }

  .views-row {
    margin-bottom: 20px;
  }

  a {
    color: $black;
    &:hover {
      color: $color-link;
    }
  }

  .photo img {
    background: $silver;
    max-width: 50%;
    border-radius: 100%;
  }

  .name {
    @include font-size(18px);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  h2,
  h3 {
    margin-bottom: 0;
  }

  .contact-email,
  .email,
  .contact-phone,
  .telephone,
  .contact-fax,
  .fax,
  .contact-address,
  .address,
  .field-name-field-ref-organization {
    @include font-size(12px);
    &:before {
      margin-right: 8px;
    }
  }

  .contact-email,
  .email {
    @include icon-before(mail);
  }
  .contact-phone,
  .telephone {
    @include icon-before(phone);
  }
  .contact-fax,
  .fax {
    @include icon-before(fax);
  }
  .contact-address,
  .address {
    @include icon-before(marker);
  }
  .field-name-field-ref-organization {
    @include icon-before(tags);
  }

  .photo,
  .name,
  .rol {
  }
}
