////
/// Footer Layout
/// @group Layout
////

.l-footer {
  // @extend %container;
  display: flex;
  flex-direction: column;
}
