// Nodes Grid
//--------------------------------------------------------------

// Layout
//--------------------------------------------------------------
.node-grid {
  margin: 10px 0;
  padding: 20px 0;
  width: 100%;
  margin-bottom: 3%;

  .view-content {
    display: flex;
    flex-wrap: wrap;
    .views-row {
      flex: 1 0 300px;
      margin-bottom: 20px;
      padding: 0 10px;
      // @include breakpoint($s) {
      @media (min-width:$s) {
        max-width: 50%;
      }
      // @include breakpoint($m) {
      @media (min-width:$m) {
        max-width: 33%;
      }
    } // .views-row
  } // .view-content
} // .node-grid

// Styles
//--------------------------------------------------------------
.node-grid {
  h2,
  h3 {
    text-transform: uppercase;
    font-weight: 700;
  }

  a {
    color: $black;
    &:hover {
      color: $color-link;
    }
  }

  &:nth-child(even) {
    position: relative;
    // margin: 0 -100%;
    // padding: 5% 100%;
    // background: rgba(0, 0, 0, 0.018);
    // width: 300%;
  }

  &:last-child {
    background: transparent;
  }

  &.small-title {
    h2.block-title {
      @include font-size(14px);
      text-align: left !important;
      @media screen and (max-width: 400px) {
        @include font-size(14px);
        text-align: left !important;
      }
      @media screen and (max-width: 768px) {
        @include font-size(14px);
        text-align: left !important;
      }
    }
  }

  h2.block-title {
    margin: 0 0 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid $color-divider;
    text-align: center;
    text-transform: uppercase;
    @include font-size(50px);
    font-weight: 800;
    @media screen and (max-width: 400px) {
      @include font-size(24px);
    }
    @media screen and (max-width: 768px) {
      @include font-size(38px);
    }
  }

  img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  span {
    @include font-size(12px);
    text-transform: uppercase;
    &.label,
    &.date {
      padding: 2px 26px;
      font-weight: 700;
    }
    &.label {
      background: $black;
      color: #fff;
    }
    &.date {
      background: $black;
      background: $color-accent;
    }
  }

  h2.title,
  h3.title {
    @include font-size(18px);
  }

  .lead {
    @include font-size(14px);
    color: $color-secondary-text;
  }

  .more a {
    display: inline-block;
    margin-top: 0px;
    padding: 5px 0px 3px;
    background: transparent;
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 12px;
    line-height: 12px;
    letter-spacing: 2px;
    @include font-size(12.5px);
    @include icon-after(right);
    &:after {
      margin-left: 5px;
      font-weight: 900;
    }

    &:hover {
      background: transparent;
      color: $color-link-hover;
    }
  }

  .more-link {
    @include icon-after(right);
    @include font-size(12px);
    font-weight: 800;
    text-transform: uppercase;
    display: block;
    right: 0px;
    margin-top: -40px;
    margin-bottom: 40px;
    @media screen and (max-width: 400px) {
      // top: 80px;
    }
    @media screen and (max-width: 768px) {
      // top: 80px;
    }
    &:after {
      margin-left: 10px;
    }
  }
} // .node-grid
