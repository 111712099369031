// SOCIAL MENU
//----------------------------------------------
.nav-social {
  margin: 20px auto;

  ul.menu li {
    @extend %inline-block;
    @extend %list-reset;
    padding: 0 10px;
  }

  // #home,
  // #google,
  // #vimeo,
  // #rss,
  // #search,
  // #contact,
  #twitter,
  #facebook,
  #youtube,
  #instagram {
    @include size(36px);
    font-size: 0;
    line-height: 0;
    text-align: center;
    background-color: $black;
    color: #fff;
    border-radius:100%;
    &:before {
      @include font-size(14px);
      line-height: 36px;
    }
  }


  #twitter {
    @include icon-before(twitter);
    &:hover {background-color: $twitter;}
  }

  #facebook {
    @include icon-before(facebook);
    &:hover {background-color: $facebook;}
  }

  #youtube {
    @include icon-before(youtube);
    &:hover {background-color: $youtube;}
  }

  #instagram {
    @include icon-before(instagram);
    &:hover {background-color: $instagram;}
  }

  // #home {
  //   @include icon-before(home);
  //   &:hover {}
  // }

  // #google {
  //   @include icon-before(google);
  //   &:hover {background-color: $google;}
  // }

  // #vimeo {
  //   @include icon-before(vimeo);
  //   &:hover {background-color: $vimeo;}
  // }

  // #rss {
  //   @include icon-before(rss);
  //       &:hover {background-}
  // }

  // #search {
  //   @include icon-before(search);
  //       &:hover {background-}
  // }

  // #contact {
  //   @include icon-before(mail);
  //       &:hover {background-}
  // }

}
