// PARAGRAPHS
//----------------------------------------------

.field-link-paragraph-iframe {
  iframe {
    border: none;
    width: 100%;
    min-height: 450px;
    height: 100%;
  }
}

.paragraphs-item-paragraph-cta {
  .field-link-paragraph-cta {
    display: flex;
    align-items: center;
    justify-content: center;

    @include btn($color-accent);
  }
}

// Texto Destacado
//------------------------------

.paragraphs-item-paragraph-text-highlight {
  // @media (min-width:$m) {
  //   max-width: 90%;
  // }

  margin: 0 auto 40px auto;
  @include font-size(16px);
  font-weight: 400;
  line-height: 16px;
  background: rgba($color-primary, 0.1);
  // color: rgba(0, 0, 0, 0.4);

  h3.paragraph-title {
    margin-top: 0px;
    margin-bottom: 0px;
    @include font-size(18px);
    font-weight: 700;
    text-transform: uppercase;
    // text-align: center;
    padding: 0.5em 1em;
    background: rgba($color-primary, 0.2);
    // color: rgba(0, 0, 0, 0.7);
  }

  .field-name-field-txt-paragraph-body {
    padding: 1em;
  }
}
