
.b-proyectos {  
  .view-content {
    align-items: flex-start;
    .views-row {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px !important;
      border-right: 1px dashed rgba(0,0,0,0.2);

      &:nth-child(3n+3){
        border-right: 0px dashed #4f425b;
      }

      figure {
        flex: 1 1 100%;
      }
      
      img {
        margin-bottom: 0px;
      }

      h2.title, h3.title {
        flex: 1 1 100%;
        @include font-size(20px);
        text-transform: uppercase;
        margin-top: 6px;
        font-weight: 700;
        line-height: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 8px 0px 4px;
        text-align: left;
        background: rgba(255,255,255,0.05);
      }

      span {
        @include font-size(12px);
        font-weight: 600;
        text-transform: uppercase;
        padding: 4px 0px 2px;
        line-height: 16px;
        flex: 1 1 100%;
        &.programa {
          background: rgba(255, 255, 255, 0.1);
          display: block;
          font-weight: 500;
          text-align: left;
          color: black;
        }
        &.date {
          background: rgba(255, 255, 255, 0.15);
          color: $color-link;
          text-align: left;
          display: block;
          padding: 0px !important;
          span {
            padding: 0px !important;
          }
        }
      } // .views-row
    } // .view-content
  }
} // .b-proyectos