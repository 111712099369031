////
/// Featured Layout
/// @group Layout
////

.l-featured {
  @include when-inside('.front') {
    @extend %container;
    border-bottom: 1px solid $color-divider;
    margin: 20px 0;
    padding: 20px 0;
  }
}
