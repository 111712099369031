// Front page
//----------------------------------------------------

.front {
  .field-name-title {
    display: none;
  }
}

.slick--view--frontpage-slideshow {

// layout
//----------------------------------
  .slide__content {
    display: flex;
    flex-direction: column;
    .slide__media,
    .slide__caption {
      // flex: 1 0 span(6);
      flex: 1 0 49.1525423729%;
    }
    // @include breakpoint($m) {
    @media (min-width:$m) {
      flex-direction: row;
      .slide__media {order: 2;}
      .slide__caption {order: 1;}
    }
  }

// style
//----------------------------------
  h2.slide__title {
    font-weight: 500;
    @include font-size(40px);
    a {
      color: $black;
      &:hover {
        color: $color-link;
      }
    }
  }

  .slide__link {
    a {
      padding: 8px;
      background: $color-link;
      color: #fff;
      @include icon-after(right);
      &:after {
        margin-left: 20px;
      }
    }
  }

// ?
//---

  .slick-dots {
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
    li {
      cursor: pointer;
      transition: all 250ms ease-in-out;
      font-size: 0;
      line-height: 0;
      margin: 4px;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: $black;
      &:hover, &:focus {
        background: $color-accent;
      }
      &.slick-active {
        background: $color-link;
      }
    }
  }

// .slick-dots li {
//   button:before {
//     color: $color-primary;
//     opacity: 1;
//   }
//   &.slick-active button:before {
//     color: #ec1c24;
//     opacity: 1;
//   }
// }

} // .slick--view--frontpage-slideshow
