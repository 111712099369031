////
/// Fonts
/// @group Variables
////

// Fontello Icons
//----------------------------------------------------
@font-face {
  font-family: 'fontello';
  src: url(../fonts/fontello/font/fontello.eot);
  src: url(../fonts/fontello/font/fontello.eot#iefix) format("embedded-opentype"),
       url(../fonts/fontello/font/fontello.woff) format("woff"),
       url(../fonts/fontello/font/fontello.ttf) format("truetype"),
       url(../fonts/fontello/font/fontello.svg#fontello) format("svg");
  font-weight: normal;
  font-style: normal;
}
$icon: "fontello";

// Sans
//----------------------------------------------------
$sans: "Helvetica Neue", Helvetica, Arial, sans-serif;

// Geomanist
//----------------------------------------------------

// 1. Thin
// 2. Thin Italic
// 3. ExtraLight
// 4. ExtraLight Italic
// 5. Light
// 6. Light Italic
// 7. Regular
// 8. Regular Italic
// 9. Book
// 10. Book Italic
// 11. Medium
// 12. Medium Italic
// 13. Bold
// 14. Bold Italic
// 15. Black
// 16. Black Italic
// 17. Ultra
// 18. Ultra Italic
// Usage

// 1. Thin
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-thin.eot);
  src: url(../fonts/geomanist/geomanist-thin.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-thin.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-thin.woff) format('woff'),
       url(../fonts/geomanist/geomanist-thin.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-thin.svg#geomanist) format('svg');
  font-weight: 100;
  font-style: normal;
}

// 2. Thin Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-thin-italic.eot);
  src: url(../fonts/geomanist/geomanist-thin-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-thin-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-thin-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-thin-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-thin-italic.svg#geomanist) format('svg');
  font-weight: 100;
  font-style: italic;
}

// 3. ExtraLight
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-extralight.eot);
  src: url(../fonts/geomanist/geomanist-extralight.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-extralight.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-extralight.woff) format('woff'),
       url(../fonts/geomanist/geomanist-extralight.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-extralight.svg#geomanist) format('svg');
  font-weight: 200;
  font-style: normal;
}

// 4. ExtraLight Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-extralight-italic.eot);
  src: url(../fonts/geomanist/geomanist-extralight-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-extralight-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-extralight-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-extralight-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-extralight-italic.svg#geomanist) format('svg');
  font-weight: 200;
  font-style: italic;
}

// 5. Light
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-light.eot);
  src: url(../fonts/geomanist/geomanist-light.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-light.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-light.woff) format('woff'),
       url(../fonts/geomanist/geomanist-light.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-light.svg#geomanist) format('svg');
  font-weight: 300;
  font-style: normal;
}

// 6. Light Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-light-italic.eot);
  src: url(../fonts/geomanist/geomanist-light-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-light-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-light-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-light-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-light-italic.svg#geomanist) format('svg');
  font-weight: 300;
  font-style: italic;
}

// 7. Regular
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-regular.eot);
  src: url(../fonts/geomanist/geomanist-regular.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-regular.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-regular.woff) format('woff'),
       url(../fonts/geomanist/geomanist-regular.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-regular.svg#geomanist) format('svg');
  font-weight: 400;
  font-style: normal;
}

// 8. Regular Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-regular-italic.eot);
  src: url(../fonts/geomanist/geomanist-regular-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-regular-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-regular-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-regular-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-regular-italic.svg#geomanist) format('svg');
  font-weight: 400;
  font-style: italic;
}

// 9. Book
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-book.eot);
  src: url(../fonts/geomanist/geomanist-book.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-book.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-book.woff) format('woff'),
       url(../fonts/geomanist/geomanist-book.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-book.svg#geomanist) format('svg');
  font-weight: 500;
  font-style: normal;
}

// 10. Book Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-book-italic.eot);
  src: url(../fonts/geomanist/geomanist-book-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-book-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-book-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-book-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-book-italic.svg#geomanist) format('svg');
  font-weight: 500;
  font-style: italic;
}

// 11. Medium
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-medium.eot);
  src: url(../fonts/geomanist/geomanist-medium.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-medium.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-medium.woff) format('woff'),
       url(../fonts/geomanist/geomanist-medium.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-medium.svg#geomanist) format('svg');
  font-weight: 600;
  font-style: normal;
}

// 12. Medium Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-medium-italic.eot);
  src: url(../fonts/geomanist/geomanist-medium-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-medium-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-medium-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-medium-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-medium-italic.svg#geomanist) format('svg');
  font-weight: 600;
  font-style: italic;
}

// 13. Bold
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-bold.eot);
  src: url(../fonts/geomanist/geomanist-bold.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-bold.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-bold.woff) format('woff'),
       url(../fonts/geomanist/geomanist-bold.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-bold.svg#geomanist) format('svg');
  font-weight: 700;
  font-style: normal;
}

// 14. Bold Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-bold-italic.eot);
  src: url(../fonts/geomanist/geomanist-bold-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-bold-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-bold-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-bold-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-bold-italic.svg#geomanist) format('svg');
  font-weight: 700;
  font-style: italic;
}

// 15. Black
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-black.eot);
  src: url(../fonts/geomanist/geomanist-black.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-black.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-black.woff) format('woff'),
       url(../fonts/geomanist/geomanist-black.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-black.svg#geomanist) format('svg');
  font-weight: 800;
  font-style: normal;
}

// 16. Black Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-black-italic.eot);
  src: url(../fonts/geomanist/geomanist-black-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-black-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-black-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-black-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-black-italic.svg#geomanist) format('svg');
  font-weight: 800;
  font-style: italic;
}

// 17. Ultra
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-ultra.eot);
  src: url(../fonts/geomanist/geomanist-ultra.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-ultra.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-ultra.woff) format('woff'),
       url(../fonts/geomanist/geomanist-ultra.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-ultra.svg#geomanist) format('svg');
  font-weight: 900;
  font-style: normal;
}

// 18. Ultra Italic
@font-face {
  font-family: 'geomanist';
  src: url(../fonts/geomanist/geomanist-ultra-italic.eot);
  src: url(../fonts/geomanist/geomanist-ultra-italic.eot?#iefix) format('embedded-opentype'),
       url(../fonts/geomanist/geomanist-ultra-italic.woff2) format('woff2'),
       url(../fonts/geomanist/geomanist-ultra-italic.woff) format('woff'),
       url(../fonts/geomanist/geomanist-ultra-italic.ttf) format('truetype'),
       url(../fonts/geomanist/geomanist-ultra-italic.svg#geomanist) format('svg');
  font-weight: 900;
  font-style: italic;
}

// Geomanist Webfont
$geomanist: 'geomanist',helvetica,arial,sans-serif;
