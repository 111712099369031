.auto-colums {
  .view-content {
    display: grid;
    gap: 1rem;
    justify-content: space-evenly;
  }
  .views-row {
    margin: 0 auto;
  }
}

.auto-colums-20 {
  .view-content {
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
}

.auto-colums-50 {
  .view-content {
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
  }
}
