.alt-order-rows, .alt-order-rows.node-grid {
  .view-content{
    .views-row {
      flex-direction: column;
      margin: 40px 0;
      display: flex;
      background: #ededed;
      padding: 0px !important;
      flex: 1 1 100% !important;
      // @include breakpoint($s) {
      @media (min-width:$s) {
        max-width: 100%;
      }
      // @include breakpoint($m) {
      @media (min-width:$m) {
        max-width: 100%;
      }

  
      .text, .media {
        flex: 1 1 100%;
      }
  
      .text {
        padding: 20px 40px;
        text-transform: uppercase;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px){
          order: 2;
        }
        h2.title {
          font-weight: 600;
          margin-top: 0px;
          margin-bottom: 30px;
          @include font-size(40px);
          line-height: 40px;
          span {
            @include font-size(40px);
            text-transform: capitalize;
            display: block !important;
            font-weight: 300;
          }
        }
        .more a {
          padding: 8px 16px 7px;
          background: $color-link;
          color: #fff;
          text-transform: uppercase;
          font-weight: 800;
          line-height: 12px;
          line-height: 12px;
          letter-spacing: 2px;
          border: 1px solid $color-link;
          @include font-size(12.5px);
          @include icon-after(right);
          &:after {
            margin-left: 5px;
            font-weight: 900;
          }
    
          &:hover {
            background: transparent;
            color: $color-link-hover;
          }
        }
      }
  
      .media {
        @media screen and (max-width: 768px){
          order: 1;
        }
        a {
          img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            margin-bottom: 0px;
          }
        }
      }
  
      &:nth-child(2n) {
        .media {
          order: 1;
        }
        .text {
          order: 2;
        }
      }
  
      // @include breakpoint($m) {
      @media (min-width:$m) {
        flex-direction: row;
        .text,
        .media {
          flex: 1 1 50%;
        }
      }
    }
  } // .views-row
} // .alt-order-rows
