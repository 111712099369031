.b-agenda {
  .view-content {
    .views-row {
      text-align: center;

      .fecha {
        background: rgba($color-primary, 0.3);
        color: rgba(0, 0, 0, 0.9);
        padding: 8px;
        @include icon-before(date);
        span {
          margin-left: 4px;
          color: rgba(0, 0, 0, 0.4);
          font-weight: 500;
        }
        &:before {
        }
      }

      .title {
        margin-top: 0px;
        margin-bottom: 0px;
        min-height: 100px;
        background: rgba($color-primary, 0.2);
        font-size: 16px;
        padding: 12px;
        a {
          color: rgba(0, 0, 0, 0.7);
          &:hover {
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }

      .lugar {
        font-size: 14px;
        line-height: 16px;
        background: rgba($color-primary, 0.1);
        padding: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.4);
        @include icon-before(marker);
        &:before {
          color: #000;
        }
      }

      // &:nth-child(2),
      // &:nth-child(5)
      &:nth-of-type(3n + 2) {
        // border-right: 1px dotted $color-divider;
        // border-left: 1px dotted $color-divider;
      }
    }
  }
}
