// Conozca más sobre nosotros
//------------------------------
.b-conozca-nosotros {
  // background-color: #edfafc;
}
.b-lugares-trabajamos {
  background-color: #edfafc;
}

.about-us-ctas {
  @extend %wide;
  margin-top: 2em;
  margin-bottom: 2em;
  padding-top: 2em;
  padding-bottom: 2em;
  h2.block-title {
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.cta-columns {
  display: flex;
  justify-content: space-between;
  // justify-content: space-around;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 2em;
  .cta-column {
    padding: 1em;
    // width: 100%;
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3 {
      color: #125e8e;
    }
    a {
      padding: 1em;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      background-color: #000;
      color: #fff;
      @include icon-after(right);
      &:after {
        margin-left: 0.5em;
      }
      &:hover {
        background-color: #f9f608;
        color: #000;
      }
    }
  }
  @media (min-width: 800px) {
    flex-direction: row;
    .cta-column {
      width: 50%;
    }
  }
}

// Conozca más sobre nosotros
//------------------------------

// Enlaces Organización
//------------------------------
.field-name-enlaces-organizacion {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
  .enlace {
    font-weight: 500;
    a {
      color: #000;
      border: 1px solid #d95e47;
      padding: 10px;
      @include icon-after(right);
      font-size: 18px;
      &:after {
        margin-left: 0.5em;
      }
      &:hover {
        border-color: #000;
        background-color: #000;
        color: #fff;
      }
    }
  }
}
