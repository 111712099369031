//--------------------------------------------------------------
// Taxonomy Display
//--------------------------------------------------------------

.v-display-premios-nacionales {
  // Filters
  //----------------------------
  .view-filters {
    margin: 40px 0;
  }

  // Layout (Sets grid)
  //----------------------------
  .view-content {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
  }

  // Group Header
  //----------------------------
  h3.views-group-title {
    grid-column: 1/-1;
    margin: 0 0 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid $color-divider;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    @include font-size(36px);
    @media screen and (max-width: 400px) {
      @include font-size(24px);
    }
    a {
      color: $black;
      &:hover {
        color: $color-link;
      }
    }
  }

  // Card (Grid item)
  //----------------------------
  .card {
    display: grid;
    box-shadow: 0px 1px 5px #555;
  }

  .card,
  .card_media,
  .card_media_background {
    img {
      display: none;
    }
    min-height: 200px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    position: relative !important;
  }

  .card_text {
    background: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.8));
    padding: 1em;
    color: #fff;
    a {
      color: #fff;
      &:hover {
        color: $color-link;
      }
    }
    h3.card_title {
    }
    .card_year {
      font-weight: 500;
      color: $color-link;
      @include font-size(18px);
    }
    .card_link {
      align-self: end;
      text-align: right;
      a {
        font-weight: 500;
        color: $color-link;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

//--------------------------------------------------------------
// Section Premios Nacionales
//--------------------------------------------------------------
.theme-premios-nacionales {
  .b-premios-nacionales-otorgados {
    margin-top: 40px;
    h2.block-title {
      @include font-size(30px);
      text-transform: uppercase;
      font-weight: 800;
      text-align: center;
    }
  }
}

//--------------------------------------------------------------
// Content type nodes
//--------------------------------------------------------------
.theme-premio-nacional-otorgado {
  .l-page,
  .l-header {
    // background-color: $magon-background;
    // color: $magon-color;
  }
  .l-main {
    margin-bottom: 40px;
  }

  .field-name-title h2 {
    @include font-size(30px);
    text-transform: uppercase;
    font-weight: 800;
  }

  .field-name-field-tax-categoria-premio-nac,
  .field-name-field-date-year-premio-nac {
    font-weight: 500;
    a {
      color: $color-link-hover;
      @include on-event {
        color: $color-link;
      }
    }
  }
  .cronologia {
    clear: both;
  }
}

// Retrato / Imagen Principal
//------------------------------

// .vocabulary-categorias-premios-nacionales.view-mode-full .field-name-field-img-main,
.theme-premio-nacional-otorgado .view-mode-full,
.vocabulary-categorias-premios-nacionales {
  .field-name-field-img-main {
    box-shadow: 0px 1px 5px #555;
    img {
      margin: 0;
      display: block;
    }
    // @include breakpoint($m) {
    @media (min-width:$m) {
      float: right;
      max-width: 40% !important;
      margin-left: 20px;
    }
  }
}

// "Navegación" de categorías de Premio Nacional (en eva field)
//------------------------------
.nav-pn {
  margin: 40px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;
  text-align: center;

  &-item {
    padding: 8px;
    @include font-size(14px);
    font-weight: 400;
    text-transform: uppercase;
    // background: $color-accent;
    // background: $color-primary;
    background: rgba($color-primary, 0.2);
    color: $black;
    a {
      color: $black;
      //&:hover {}
    }
    &:before {
      margin-right: 8px;
    }
  }

  &-home {
    @include icon-before(home);
  }
  &-categoria {
    @include icon-before(award);
  }
  &-periodo {
    @include icon-before(hourglass);
    display: none;
    .Premio-Nacional-de-Cultura-Magón & {
      display: block;
    }
  }
}

//--------------------------------------------------------------
// Taxonomy terms
//--------------------------------------------------------------
.term-categoria-premio-nacional-otorgado,
.term-periodo-premio-nacional-otorgado {
  h1.page-title {
    @include font-size(48px);
  }
}
