// .view-related-terms {
//   .views-row {
//     background-color: #f7f7f7;
//     margin-bottom: 20px;
//   }
//   h3 {
//     margin: 0;
//     font-weight: 300;
//     font-size: 14px;
//   }
// }
//   figure {
//     margin: 0;
//     a {
//       margin: 0;
//       line-height: 1;
//     }
//   }
//   img {
//     border: none!important;
//     margin: 0;
//     width: 100%;
//     max-width: 100%;
//     height: auto;
//   }


// // Element invisible.
// .element-invisible {
//   position: absolute!important;
//   clip: rect(1px, 1px, 1px, 1px);
//   overflow: hidden;
//   height: 1px;
// }

.view-mode-full {
  .field-name-field-img-main {
    // @include breakpoint($m) {
    @media (min-width:$m) {
      max-width: 50%;
      float: right;
      margin-left: 10px;
    }
  }
}
