.not-logged-in.page-node-13 {
  .b-medios {
    #edit-tid-wrapper, #edit-tid-1-wrapper {
      display: none;
    }
  }
}


.b-medios {
  .Vídeo {
    position: relative;
      &:before {
        content: '';
        width: 50px;
        height: 32px;
        top: calc(50% - 32px);
        left: calc(50% - 25px);
        background-image: url(../img/video.png);
        background-color: rgba(0,0,0,0.3);
        display: block;
        transform-origin: center center;
        position: absolute;
        background-repeat: no-repeat;
        box-shadow: 0 0 60px black;
      }
  }

  .Audio {
    position: relative;
    &:before {
      content: '';
      width: 51px;
      height: 40px;
      top: calc(50% - 40px);
      left: calc(50% - 25px);
      background-image: url(../img/audio.png);
      background-color: rgba(0,0,0,0.3);
      display: block;
      transform-origin: center center;
      position: absolute;
      background-repeat: no-repeat;
      box-shadow: 0 0 60px black;
    }
  }

  .Documento {
    position: relative;
    &:before {
      content: '';
      width: 30px;
      height: 35px;
      top: calc(50% - 35px);
      left: calc(50% - 15px);
      background-image: url(../img/doc.png);
      background-color: rgba(0,0,0,0.3);
      display: block;
      transform-origin: center center;
      position: absolute;
      background-repeat: no-repeat;
      box-shadow: 0 0 60px black;
    }
  }

  img {
    margin-bottom: 0px !important;
    transition: 0.3s all;

    &:hover {
      opacity: 0.7;
    }
  }

  .type {
    // display: none;
  }

  h2.title {
    margin: 0px;
    a {
      @include font-size(16px);
      line-height: 16px;
      text-transform: uppercase;
      color: #261b29;
      background: #fff;
      font-weight: 900;
      display: block;
      padding: 0px 0px 6px;
      &:after {
        display: none !important;  
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }

  .program {
    display: none;
  }
}