////
/// Branding Layout
/// @group Layout
////

// Branding (Logo, Site name, Site slogan)
//----------------------------------------------------
.l-branding h1.site-name {
  margin: 0;
  padding: 0;
  border-bottom: 0px;
}

.l-branding h1.site-name a {
  background-size: 100%;
  // @include logo(logo, 90px, 75px, 0 0px);
  @include logo(logo-dgsc_nuevo, 200px, 83px, 0 0px);

  @media screen and (min-width: 401px) {
    // @include logo(logo, 50px, 42px, 0 0px);
    @include logo(logo-dgsc_nuevo, 320px, 50px, 0 0px);
  }

  // @include breakpoint($m) {
  @media (min-width:$m) {
    // @include logo(logo, 65px, 55px, 0 0px);
    @include logo(logo-dgsc_nuevo, 500px, 63px, 0 0px);
  }
}

// Logos
//----------------------------------------------------
.b-logo-mcj,
.b-logo-presidencia {
  .block-content {
    a {
      background-size: 100%;
      filter: grayscale(1);

      &:hover {
        filter: grayscale(0);
      }
    }

    border-left: 1px solid $silver;
    margin: 14px 0 14px 8px;
    padding: 0 0 0 8px;

    // @include breakpoint($s) {
    @media (min-width:$s) {
      margin: 22px 0 22px 16px;
      padding: 0 0 0 16px;
    }

    // @include breakpoint($m) {
    @media (min-width:$m) {
      margin: 24px 0 24px 24px;
      padding: 0 0 0 24px;
    }
  }
}

.b-logo-mcj .block-content a {
  @include logo(logo-mcj, 48px, 32px);
}

.b-logo-presidencia .block-content a {
  // @include logo(logo-cr, 48px, 32px);
  @include logo(logo-presidencia-2022, 64px, 32px);
}

.l-branding {
  @media screen and (max-width: 400px) {
    flex: 1 1 100%;
  }

  .block-content {
    border-left: 1px solid $silver;
    margin: 14px 0 14px 8px;
    padding: 0 0 0 8px;

    @media screen and (max-width: 400px) {
      border-left: none;
      margin: 4px 0 4px 0px;
      padding: 0 0 0 0px;

      h1 a {
        margin: auto;
      }
    }

    // @include breakpoint($s) {
    @media (min-width:$s) {
      margin: 22px 0 22px 16px;
      padding: 0 0 0 16px;
    }

    // @include breakpoint($m) {
    @media (min-width:$m) {
      margin: 24px 0 24px 24px;
      padding: 0 0 0 24px;
    }
  }
}

.b-logo-presidencia {
  .block-content a {

    // @include breakpoint($s) {
    @media (min-width:$s) {
      width: 80px;
      height: 42px;
    }

    // @include breakpoint($m) {
    @media (min-width:$m) {
      width: 104px;
      height: 54px;
    }
  }
}

.b-logo-mcj {
  .block-content a {

    // @include breakpoint($s) {
    @media (min-width:$s) {
      width: 64px;
      height: 42px;
    }

    // @include breakpoint($m) {
    @media (min-width:$m) {
      width: 80px;
      height: 54px;
    }
  }
}

.b-logo-presidencia {
  .block-content {
    border-left: 0px solid $silver;
    margin-left: 0px;
  }
}
